<template>
	<div>
		<!-- 公共头部 -->
	<Header></Header>
		
		<div class="view1" v-if="tikaData.problem.length">
			<div class="view1-3">
				<div class="alert-view1">
					<span class="type">{{tikaData.problem[curIndex].type}}</span>
					<div class="question_title">{{curIndex + 1}}/{{tikaData.problem.length}}、{{tikaData.problem[curIndex].title}}<font color="#fc2221">（正确答案：{{tikaData.problem[curIndex].daan}}）</font></div>
					<img class="img" v-show="tikaData.problem[curIndex].img" :src="tikaData.problem[curIndex].img" />
				</div>
				<div class="alert-view2 clearfix">
					<li class="clearfix" :class="[tikaData.problem[curIndex].p_type == 2 ? 'active' : '' ] " v-for="(item,index) in tikaData.problem[curIndex].answer" :key = "index">
						<label class="radio fl" v-if="tikaData.problem[curIndex].type == '单选题' || tikaData.problem[curIndex].type == '判断题'">
							<input class="input_radio" type="radio" :value="item.option" :disabled="tikaData.problem[curIndex].disabled" :checked="item.ischecked">
							<span></span>
							<a v-if="tikaData.problem[curIndex].p_type == 1">{{item.option}}、{{item.title}}</a>
							<a v-if="tikaData.problem[curIndex].p_type == 2">{{item.option}}、</a>
							<div class="img-box" v-if="tikaData.problem[curIndex].p_type == 2">
								<img class="img" :src="item.img" />
							</div>
						</label>
						<label class="checkbox fl" v-if="tikaData.problem[curIndex].type == '多选题'">
							<input class="input_checkbox" type="checkbox" :value="item.option" :disabled="tikaData.problem[curIndex].disabled" v-model="item.ischecked">
							<span></span>
							<a v-if="tikaData.problem[curIndex].p_type == 1">{{item.option}}、{{item.title}}</a>
							<a v-if="tikaData.problem[curIndex].p_type == 2">{{item.option}}、</a>
							<div class="img-box" v-if="tikaData.problem[curIndex].p_type == 2">
								<img class="img" :src="item.img" />
							</div>
						</label>
					</li>
				</div>
			</div>
			<div class="view1-4">
				<div class="btn" @click="prev">上一题</div>
				<div class="btn" v-if="showDTK" @click="showDTK = false">收起答题卡</div>
				<div class="btn" v-else-if="!showDTK" @click="showDTK = true">显示答题卡</div>
				<div class="btn btn1" @click="next">下一题</div>
			</div>
		</div>
		<div class="view2" v-if="tikaData.problem.length">
			<div class="cate-title">本题详解</div>
			<div class="singlepage" v-html="tikaData.problem[curIndex].analysis||'暂无解析'"></div>
		</div>
		
		<div class="mask" v-if="showDTK" @click="showDTK = false"></div>
		<div class="blank_100"></div>
		<div class="tools-box" v-if="tikaData.problem.length">
			<div class="tools">
				<!-- 收藏 -->
				<div class="shoucang-box" v-if="tikaData.problem[curIndex].is_shoucang == 0" @click="shoucang(tikaData.problem[curIndex].id,2)">
					<img class="icon" src="@/assets/images/icon97.png" />
					<span class="text">收藏</span>
				</div>
				<div class="shoucang-box" v-if="tikaData.problem[curIndex].is_shoucang == 1" @click="removes(tikaData.problem[curIndex].id,4)">
					<img class="icon" src="@/assets/images/icon98.png" />
					<span class="text">取消收藏</span>
				</div>
				<!-- 收藏 -->
				
				<!-- 数字盒子 -->
				<div class="num-box">
					<div class="item">{{tikaData.sure}}</div>
					<div class="item">{{Number(tikaData.wrong) + Number(tikaData.leak)}}</div>
					<div class="item">{{curIndex + 1}}/{{tikaData.problem.length}}</div>
				</div>
				<!-- 数字盒子 -->
			</div>
			<div class="view3" v-show="showDTK">
				<ul class="list clearfix">
					<li class="item fl" v-for="(item,index) in tikaData.problem" @click="curIndex = index" :class="[item.status == 0 ? 'red' : '',item.status == 1 ? 'blue' : '',item.status == 2 ? 'red' : '',curIndex == index ? 'green' : '' ]" :key = "index">{{index + 1}}</li>
				</ul>
				<div class="close-btn">
					<span class="btn" @click="showDTK = false">关闭</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Header from '@/components/Public/Header.vue';
	export default {
		components: {
			Header
		},
		data() {
			return {
				tikaData: {
					subject_title: '',
					problem: []
				},
				curIndex: 0,
				showDTK: false
			}
		},
		computed: {
			uid() {
				return this.$store.state.uid;
			}
		},
		activated() {
			this.getUserInfo();
		},
		deactivated() {
			this.tikaData = {
				subject_title: '',
				problem: []
			};
			this.curIndex = 0;
			this.showDTK = false;
		},
		methods: {
			// 移除错题&收藏
			async removes(problem_id,type) {
				var student_id = this.tikaData.student_id;
				var uid = this.uid;
				var data = {
					problem_id, student_id, uid, type
				}
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.post('/study/removes',data);
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('模拟回顾 移除错题&收藏：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					this.tikaData.problem[this.curIndex].is_shoucang = 0;
				} else {
					this.$messageBox.alert(res.data, '温馨提示');
				}
			},
			// 收藏错题
			async shoucang(problem_id,type) {
				if(!problem_id){
					return false;
				}
				var student_id = this.tikaData.student_id;
				var uid = this.uid;
				var data = {
					problem_id, student_id, type, uid
				}
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.post('/study/shoucang',data);
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('模拟回顾 收藏错题：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					if(type == 2){
						this.tikaData.problem[this.curIndex].is_shoucang = 1;
					}
				} else {
					this.$messageBox.alert(res.data, '温馨提示');
				}
			},
			// 考试回顾
			async getInfo() {
				var exam_id = this.$route.query.exam_id;
				var uid = this.uid;
				var data = {
					exam_id, uid
				}
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.get('/study/review', { params: data });
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('模拟回顾 详情：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					var tikaData = res.data;
					for(var i = 0; i < tikaData.problem.length; i++) {
						tikaData.problem[i]['disabled'] = true;
						if(tikaData.problem[i]['img'] && tikaData.problem[i]['img'].substring(0,4) != 'http'){
							tikaData.problem[i]['img']=this.$store.state.http + tikaData.problem[i]['img'];
						}
						if(tikaData.problem[i]['p_type'] == 2){
							for(var j = 0; j < tikaData.problem[i]['answer'].length; j++){
								if(tikaData.problem[i]['answer'][j]['img'] && tikaData.problem[i]['answer'][j]['img'].substring(0,4) != 'http'){
									tikaData.problem[i]['answer'][j]['img']=this.$store.state.http + tikaData.problem[i]['answer'][j]['img'];
								}
							}
						}
					}
					this.tikaData = tikaData;
				} else {
					this.$messageBox.alert(res.data);
				}
			},
			// 获取用户信息
			async getUserInfo(){
				if(!this.uid){
					const confirmResult = await this.$messageBox.confirm('您当前未登录，请登录后再进行操作！', '登录提醒').catch(error => error);
					if(confirmResult != 'confirm'){
								wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
						return ;
					}
							wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
				} else {
					this.getInfo();
				}
			},
			// 上一题
			prev() {
				if(this.curIndex != 0) {
					this.curIndex = --this.curIndex;
				} else {
					this.$message('已经到达第一题');
				}
			},
			// 下一题
			next() {
				if((this.curIndex + 1) != this.tikaData.problem.length) {
					this.curIndex = ++this.curIndex;
				} else {
					this.$message('已经到达最后一题');
				}
			},
		},
	};
</script>

<style scoped>
	.view1{
		background: #FFFFFF;
		padding: 0.3rem;
		margin-bottom: 0.3rem;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
	}
	.view1 .view1-3{
		position: relative;
	}
	.view1 .view1-3 .alert-view1{
		position: relative;
		padding-left: 1.1rem;
	}
	.view1 .view1-3 .alert-view1 .type{
		width: 0.9rem;
		height: 0.4rem;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #121e5f;
		font-size: 0.24rem;
		color: #ffffff;
		position: absolute;
		border-radius: 0.06rem;
		left: 0;
		top: 0;
	}
	.view1 .view1-3 .alert-view1 .question_title{
		font-size: 0.26rem;
		color: #333333;
		line-height: 0.4rem;
	}
	.view1 .view1-3 .alert-view1 .img{
		display: block;
		margin: 0.3rem auto 0;
	}
	.view1 .view1-3 .alert-view2{
		margin-top: 0.2rem;
	}
	.view1 .view1-3 .alert-view2 .active{
		float: left;
		width: 50%;
	}
	.view1 .view1-4{
		margin-top: 0.6rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.view1 .view1-4 .btn{
		width: 1.6rem;
		height: 0.66rem;
		border: 0.02rem solid #d6d6d6;
		background: #ffffff;
		border-radius: 0.5rem;
		font-size: 0.24rem;
		color: #333333;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.view1 .view1-4 .btn1{
		border: 0.02rem solid #121e5f;
		background: #121e5f;
		color: #ffffff;
	}
	
	.view2{
		margin-bottom: 0.3rem;
	}
	.view2 .cate-title{
		height: 0.88rem;
		display: flex;
		align-items: center;
		font-size: 0.32rem;
		color: #333333;
		padding-left: 0.46rem;
		position: relative;
	}
	.view2 .cate-title::before{
		content: '';
		display: inline-block;
		position: absolute;
		width: 0.05rem;
		height: 0.26rem;
		background: #ff6000;
		left: 0.3rem;
		top: 50%;
		transform: translate(0,-50%);
		-ms-transform: translate(0,-50%);
		-o-transform: translate(0,-50%);
		-moz-transform: translate(0,-50%);
		-webkit-transform: translate(0,-50%);
	}
	.view2 .singlepage{
		font-size: 0.26rem;
		color: #333333;
		line-height: 0.4rem;
		padding: 0.3rem;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
		background: #ffffff;
	}
	
	.tools-box{
		position: fixed;
		width: 100%;
		left: 0;
		bottom: 0;
		background: #ffffff;
		z-index: 999;
	}
	.tools-box .tools{
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 0.98rem;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
		padding: 0 0.3rem;
	}
	.tools-box .tools .shoucang-box{
		display: flex;
		align-items: center;
		height: 0.4rem;
	}
	.tools-box .tools .shoucang-box .icon{
		height: 0.24rem;
	}
	.tools-box .tools .shoucang-box .text{
		font-size: 0.26rem;
		color: #333333;
		margin-left: 0.16rem;
	}
	.tools-box .tools .num-box{
		display: flex;
		align-items: center;
	}
	.tools-box .tools .num-box .item{
		font-size: 0.26rem;
		color: #333333;
		line-height: 0.4rem;
		margin-right: 0.36rem;
		padding-left: 0.4rem;
		background-position: left center;
		background-repeat: no-repeat;
		background-size: 0.24rem 0.24rem;
	}
	.tools-box .tools .num-box .item:nth-child(1){
		background-image: url(../../../assets/images/icon90.png);
	}
	.tools-box .tools .num-box .item:nth-child(2){
		background-image: url(../../../assets/images/icon91.png);
	}
	.tools-box .tools .num-box .item:nth-child(3){
		background-image: url(../../../assets/images/icon92.png);
	}
	.tools-box .tools .num-box .item:last-child{
		margin-right: 0;
	}
	
	.view3{
		
	}
	.view3 .list{
		padding: 0.2rem 0.4rem;
		height: 6rem;
		overflow-y: scroll;
	}
	.view3 .list .item{
		width: 0.8rem;
		height: 0.8rem;
		background: #ffffff;
		border: 0.02rem solid #333333;
		font-size: 0.32rem;
		color: #333333;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 100%;
		margin-right: 0.38rem;
		margin-top: 0.3rem;
	}
	.view3 .list .item:nth-child(1),
	.view3 .list .item:nth-child(2),
	.view3 .list .item:nth-child(3),
	.view3 .list .item:nth-child(4),
	.view3 .list .item:nth-child(5),
	.view3 .list .item:nth-child(6){
		margin-top: 0;
	}
	.view3 .list .item:nth-child(6n){
		margin-right: 0;
	}
	.view3 .list .item.red{
		border: 0.02rem solid #ff2828;
		background: #ffe4e4;
		color: #ff2828;
	}
	.view3 .list .item.blue{
		border: 0.02rem solid #009d30;
		background: #d9ffe5;
		color: #009d30;
	}
	.view3 .list .item.green{
		border: 0.02rem solid #1D6BD0;
		background: #c8dcf7;
		color: #1D6BD0;
	}
	
	.view3 .close-btn{
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0.2rem 0;
		border-top: 0.02rem solid #dddddd;
	}
	.view3 .close-btn .btn{
		display: flex;
		align-items: center;
		justify-content: center;
		height: 0.6rem;
		width: 1.5rem;
		background: #121e5f;
		color: #ffffff;
		border-radius: 0.5rem;
		font-size: 0.28rem;
	}
</style>